/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartItemRemovePopup {
    &-Content {
        p {
            font-weight: 700;
            font-size: 24px;
            margin-block-end: 25px;
            line-height: 32px;
        }
    }

    .Button {
        flex-basis: 47%;

        &-Group {
            display: flex;
            justify-content: space-between;
        }
    }

    svg {
        display: none;
    }

    .Popup {
        &-Content {
            padding: 20px 25px 25px;

            @include desktop {
                padding: 40px 45px 45px;
                width: 500px;
            }
        }
    }
}
