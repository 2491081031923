/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Header {
    &-Button_type_minicart {
        .CartOverlay {
            @include desktop {
                inset-block-start: calc(var(--header-nav-height));
                width: 400px;
                padding: 30px 50px;
                border: 1px solid $border-color;
                box-shadow: 0 1px 1px $border-color;
            }
            
            &::before {
                display: none;
            }
        
            .CartOverlay-Promo {
                @include desktop {
                    background-color: $white;
                    color: $default-primary-base-color;
                    text-align: start;
                    font-size: 14px;
                    font-weight: 400;
                    border-bottom: 1px solid $border-color;
                    padding-inline-start: 0px;
                }
            }
        
            &-ContentWrapper {
                border: 0;
            }
        
            &-Items {
                padding-inline-start: 0;
                padding-inline-end: 0;
                
                @include desktop {
                    max-height: 54vh;
                }   
            }
        
            &-Actions {
                padding-inline-start: 0;
                padding-inline-end: 0; 
                
                .CartOverlay-CartButton {
                    width: 100%;
                    text-transform: none;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 4px;
                }
            }
        
            &-Total {
                font-weight: 400;
                font-size: 14px;
                padding: 20px 0 5px;
                background-color: transparent;
                color: $default-primary-base-color;
                border-top: 1px solid $border-color;
            }
        }
    }
}
