/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartPageWishlist {
    margin-block-start: 30px;
    
    .Table-Title {
        border-bottom: 1px solid $border-color;
        padding-block-end: 20px;   
        margin-block-end: 10px; 

        @include mobile {
            padding-block-end: 10px;
        }
    }

    .CartMyWishlist {
        &-Products {
            @include desktop {
                display: block;
            }

            .WishlistItem {
                &-ProductCard {
                    flex-direction: row;
                    border-bottom: 1px solid var(--primary-divider-color);
                    padding-block: 20px;
                }

                &-FigureWrapper {
                    @include desktop {
                        width: 208px;
                    }
                }

                &-Name {
                    @include desktop {
                        padding-inline-start: 20px;
                    }
                }

                &-Content {
                    display: flex;
                    flex-direction: column;
                    
                    @include desktop {
                        width: 40%;
                        margin-inline-start: auto;
                    }
                }

                &-RowWrapper {
                    align-items: flex-end;
                }

                &-Remove {
                    display: none;
                }

                &-Action {
                    display: flex;
                    align-items: center;

                    button, a {
                        color: $light-primary-color;
                        font-size: 12px;
                        cursor: pointer;
                        font-weight: 600;
                    }
                }

                &-Name, &-NameAndOptions {
                    h4 {
                        font-weight: 600;
                        color: $default-primary-base-color;
                        margin-block-end: 3px;
                    }
                    
                    .WishlistItemOptions {
                        &-List {
                            font-size: 12px;
                            line-height: 20px;
                            color: $light-primary-color;
                            padding-inline: 0;
                            
                            .Qty, .selected {
                                font-size: 12px;
                                line-height: 20px;
                                color: $light-primary-color;
                                padding-inline: 0;
                                display: block;
                            }
                        }
                    }
                }

                &-ActionWrapper {
                    @include desktop {
                        margin-block-start: auto;
                        margin-inline-start: auto;
                    }

                    @include mobile {
                        justify-content: flex-start;
                        margin-inline-start: 0;
                    }

                    .WislistItem-Edit {
                        @include mobile {
                            padding: 0;
                            font-size: 12px;
                            color: $light-primary-color;
                            display: none;
                        }
                    }
                }

                &-AddToCart, &-RemoveLabel {
                    @include mobile {
                        padding-block: 0;
                        padding-inline-start: 0;
                        padding-inline-end: 0;
                        font-size: 12px;
                        color: $light-primary-color;
                    }
                }

                &-Edit {
                    display: none;
                }

                &-ImageWrapper {
                    @include mobile {
                        width: 130px;
                    }
                }
            } 
            
            .ProductCard {
                &-figure {
                    width: 150px;  
                }
            }
        }
    }
}
