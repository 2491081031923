/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.AddToCart {
    /* stylelint-disable-next-line declaration-no-important */
    background-color: $addtobag-backgorund-color !important;

    &:hover {
        /* stylelint-disable-next-line declaration-no-important */
        background-color: #e93e51 !important;
        /* stylelint-disable-next-line declaration-no-important */
        border-color: #e93e51 !important;
    }

    .added {
        position: relative;

        &::before {
            content: '';
            width: 8px;
            height: 4px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            display: inline-block;
            transform: rotate(-45deg);
            vertical-align: middle;
            position: absolute;
            inset-inline-start: -13px;
            inset-block-start: 5px;
        }
    }

    &.Button[disabled] {
        opacity: 1;
    }
}
