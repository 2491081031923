/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.CartCoupon {
    &-Input {
        @include desktop {
            margin-block-end: 20px;
            flex: 1;
        }

        @include mobile {
            width: calc(100% - 142px);
        }

        input {
            @include desktop {
                width: 100%;
            }
        }

        .Field {
            margin-block-start: 0 !important;
        }
    }

    .Form {
        margin-block-start: 4px;
        flex-wrap: wrap;
    }

    &-Message {
        padding-block-end: 15px;
    }

    &-MessageText {
        strong {
            color: #028850;
        }
    }

    &-Button {
        text-transform: none;
        font-weight: 600;

        @include desktop {
            width: 117px;
        }

        @include mobile {
            width: 117px;
            margin: 0;
        }
    }
}
