/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CartPage {
    @include mobile {
        padding-block-end: 30px;
    }

    &-Wrapper {
        @include desktop {
            display: flex;
            grid-column-gap: 30px;
        }

        .CartPage-Discount {
            display: none;
        }
    }

    &-Static {
        padding-block-start: 25px;
        
        @include desktop {
            padding-block-start: 40px;
            width: calc(100% - 400px);
        }
    
        .CartItem {
            &-Wrapper {
                display: flex;
                width: 100%;
                flex-wrap: wrap; 
                align-items: unset;
                padding-inline: 0;
                padding-block: 24px;
            }
        
            &-InfoWrapper {
                margin-inline-start: auto;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                .CartItem-PriceWrapper {
                    margin-inline-start: auto;

                    .qty {
                        font-size: 12px;
                        margin-block-end: 5px;
                        
                        @include desktop {
                            display: inline-block;
                            font-weight: 600;
                        }
                    }
                }
            }

            &-ProductInfo {
                flex: 1;

                @include wide-desktop {
                    width: 65%;
                }

                @include desktop {
                    width: 57%;
                }
            }

            &-Title {
                width: 100%;

                @include desktop {
                    width: 90%;
                }
            }

            &-Heading {
                color: $default-primary-base-color;
                font-weight: 600;
            }

            &-Options {
                span {
                    font-size: 12px;
                    line-height: 20px;
                    color: $light-primary-color;

                    &.option-sku {
                        @include mobile {
                            display: block;
                            order: -2;
                        }
                    }

                    &.Qty {
                        /* stylelint-disable-next-line declaration-no-important */
                        display: none !important;
                    }
                }
            }

            .CartItem-Qty {
                display: block;
                width: 142px;
                
                @include desktop {
                    margin-inline-start: 10px;
                }

                .Field-Label {
                    font-weight: 900;
                    color: #5f5f5f;
                    font-size: 10px;
                }

                select {
                    font-weight: 600;
                }
            }

            &-ProductActions {
                button, a {
                    color: $light-primary-color;
                    font-size: 12px;
                    cursor: pointer;
                    font-weight: 600;

                    @include desktop {
                        margin-inline-start: 25px;
                    }

                    @include mobile {
                        margin-inline-end: 25px;
                    }

                    @media only screen and (max-width: 375px) {
                        margin-inline-end: 10px;
                    } 
                }
            }

            &-Picture {
                @include desktop {
                    width: 208px;
                    height: 100%;
                }

                @include mobile {
                    width: 100%;
                    height: 100%;
                }
                
                img {
                    @include desktop {
                        position: relative;
                    }
                }

                &_isMobileLayout {
                    @include mobile {
                        width: 100px;
                        height: 115px;
                    }
                }
            }
        }

        .Table {
            &-Title {
                font-size: 24px;
                margin-block-end: 5px;
                margin-block-start: 0;
                font-weight: 700;

                @include mobile {
                    font-size: 16px;
                }
            }
        }
    }

    &-Floating {
        margin-block-start: 0;
        
        @include desktop {
            background-color: #F7F7F7;
            width: 400px;
        }

        @include mobile {
            margin-block-start: 30px;
        }

        .CartPage {
            &-LastCategory {
                @include mobile {
                    padding-block-start: 10px;
                    border-top: 1px solid $border-color;
                }
            }
        }
    }

    &-Heading {
        border-bottom: 1px solid $border-color;
        padding-block-end: 20px;

        @include mobile {
            display: block;
            padding-block-start: 0px;
            padding-block-end: 15px;
            margin-block-end: 20px;
        }

        span {
            font-size: 16px;
            padding-inline-start: 10px;
        }
    }

    &-Empty {
        @include mobile {
            border-bottom: 0;
        }

        p {
            margin-block-end: 8px;
        }

        .Button {
            &.Best-Seller {
                margin-block-start: 20px;
                width: 215px;
            }
        }

        @include mobile {
            padding: 0;
            text-align: start;
        }

        .ProductSlider {
            &-Wrapper {
                padding-inline: 0;
                margin-block-start: 50px;
                margin-block-end: 40px;
                
                .SlickSlider {
                    padding-inline: 0;
                    margin-inline: -12px;

                    .ProductWishlistButton {
                        display: none;
                    }

                    .ProductConfigurableAttributes {
                        display: none;
                    }
                    
                    .ProductCard-Name {
                        margin-block-end: 0;
                        padding-block-end: 0;
                    }
                }
            }

            &-Title {
                font-size: 24px;
                text-align: start;
                border-bottom: 1px solid $border-color;
                padding-block-end: 30px;
                font-weight: 700;
                
                @include mobile {
                    font-size: 16px;
                    padding-block-end: 15px;
                }
            }
        }
    }

    &-RegisterWrapper {
        display: flex;
        flex-wrap: wrap;   
        margin-block-end: 50px;
        
        @include mobile {
            flex-direction: column-reverse;
            margin-block-end: 10px;
        }    
    }

    &-leftContent {
        padding-block-start: 15px;
        
        @include wide-desktop {
            width: 70%;
        }

        @include desktop {
            width: 60%;
            padding-inline-end: 10px;
        }

        p {
            font-size: 14px;
        }

        .btn-Group {
            @include mobile {
                display: flex;
                justify-content: space-between;
            }

            .Button_isHollow {
                font-weight: 600;
            }
        }

        .Button {
            font-size: 12px;
            font-weight: 400;
            margin-block-start: 10px;
            width: 48%;

            @include desktop {
                margin-inline-end: 5px;
            }

            @include wide-desktop {
                width: 215px;
            }
        }

        .color-green {
            @include mobile {
                display: none;
            }
        }
    }

    &-RightContent {
        @include wide-desktop {
            width: 30%;
        }

        @include desktop {
            width: 40%;
        }

        .register-top-batch {
            @include mobile {
                margin-block-start: 0;
            }

            .color-green {
                @include desktop {
                    display: none;
                }
            }

            .regsiter-process {
                @include mobile {
                    display: flex;
                    align-items: flex-start;
                    margin-block-start: 0;
                }

                li {
                    @include mobile {
                        flex-direction: column;
                        align-items: center;
                        width: 33.33%;
                        padding-inline: 14px;
                        text-align: center;
                    }

                    img {
                        @include mobile {
                            margin-inline-end: 0;
                            margin-block-end: 10px;
                        }
                    }
                    
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &-Summary {
        background-color: transparent;
    }

    .CheckoutOrderSummary {
        border: 0;
        padding-inline: 45px;
        padding-block-start: 40px;
        
        @include mobile {
            padding: 0;
            background-color: transparent;
        }

        &-Header {
            @include mobile {
                display: block;
            }
        }

        h2 {
            font-size: 16px;
        }

        &-Text, &-Price {
            font-size: 12px;
        }

        &-Price {
            strong {
                font-weight: 400;
            }
        }

        &-SummaryItem {
            &_isTotal {
                font-weight: 600;
                padding-block-start: 24px;
                padding-block-end: 24px;
                border-bottom: 1px solid $border-color;
                margin-block-end: 10px;

                @include mobile {
                    padding-block-start: 10px;
                    padding-block-end: 10px;
                    margin-block-start: 10px;
                }

                .CheckoutOrderSummary-Text {
                    font-size: 16px;
                }

                .CheckoutOrderSummary-Price {
                    strong {
                        font-weight: 600;
                        font-size: 16px;
                    }
                }
            }
        }

        &-OrderSummery, &-ItemList {
            background-color: transparent;
            padding: 0;
        }

        .Link-Group {
            display: none;
        }

        &-estimateTotalMobile {
            @include mobile {
                display: block;
            }

            .CheckoutOrderSummary-SummaryItem_isTotal {
                border-top: 1px solid $border-color;
                border-bottom: 0;
                padding-block-start: 20px;
            }
        }
    }

    &-CheckoutButton {
        background-color: $addtobag-backgorund-color;
        border-color: $addtobag-backgorund-color;

        &:hover {
            /* stylelint-disable-next-line declaration-no-important */
            background-color: #e93e51 !important;
            /* stylelint-disable-next-line declaration-no-important */
            border-color: #e93e51 !important;
        }
    }

    .ProductLinks {
        &-Wrapper {
            padding-inline: 0;
        }

        &-Title {
            @include desktop {
                font-size: 24px;
            }

            @include mobile {
                border-bottom: 1px solid $border-color;
                padding-block-end: 10px;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }

    .ProductSlider {
        &-List {
            .SlickSlider {
                .slick-list {
                    @include mobile {
                        /* stylelint-disable-next-line declaration-no-important */
                        padding-inline-start: 0 !important;
                    }
                }
            }
        }
    }

    &-Discount {
        @include desktop {
            margin-block-start: 20px;
        }

        .ExpandableContent {
            &-Heading {
                font-weight: 600;

                .icon {
                    display: none;
                }
            }
        }
    }

    &-LastCategory {
        a {
            font-size: 12px;
            font-weight: 600;
            border-bottom: 1px solid $border-color;
        }
    }
}

.color-green {
    color: #028850;
}
