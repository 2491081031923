/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.CartItemEdit {
    @include desktop {
        display: flex;
        width: 850px;
    }

    .ProductGallery {
        @include desktop {
            width: 45%;
        }

        @media (min-width: 1024px) and (max-width: 1280px) {
            min-width: 45%;
        }

        .Slider-Crumbs {
            display: none;
        }

        &-Additional {
            display: none;
        }
    }

    .ProductWishlistButton {
        display: none;
    }

    .ProductActions {
        &-AddToCartWrapper {
            justify-content: flex-start;

            @include mobile {
                padding-block-start: 0;
                border-top: 0;
            }

            .Field-Wrapper {
                &_type_select {
                    @include mobile {
                        display: block;
                    }

                    .ProductActions-Qty.Field {
                        margin-block-end: 0;
                    }
                }
            }

            .ProductActions-AddToCart {
                margin-block-start: 0;
                background-color: $default-primary-base-color !important;

                &:hover {
                    border-color: #5f5f5f !important;
                    background-color: #5f5f5f !important;
                }
            }
        }

        &-AddToCartFixed {
            display: none;
        }
    }

    .Field-Wrapper {
        &_type_select {
            @include mobile {
                display: none;
            }
        }
    }
}
