/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.AddToCartPopup {
    .Popup-mainWrapper {
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }

  .Main {
    display: flex;
    // align-items: center;
    // justify-content: center;
    width: 100%;
    gap: 3%;

    &-ProductName {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    &-Sku {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $light-primary-color;
      margin-block-end: 3px;
    }

    &-ProductQty {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: $light-primary-color;
    }
  }

  .Img {
    width: 25%;
  }

  .ProductInfo {
    width: 72%;
    display: flex;
    flex-direction: column;

    .ProductConfigurableAttributes {
      &-Size {
        margin-bottom: 3px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $light-primary-color;
      }

      &-SelectedOptionLabel {
        margin-bottom: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: $light-primary-color;
      }

      &-SelectedOptionLabelPrice {
        font-size: 14px;
        color: #1d1d1d;
        font-weight: 600;
      }
    }
  }

  .RelatedProduct {
    // overflow-x: scroll;
    width: 100%;

    @include mobile {
      margin-block-start: 20px;
    }
    
    .ProductLinks {
        &-Wrapper {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }

        &-Title {
            font-size: 14px;
            line-height: 18px;
            padding-block-end: 0;
            padding-block-start: 0;
            margin-block-end: 10px;
        }

        &-Slider {
          padding: 0;
          margin-inline: -10px;
          margin-block-end: 0;
        }
     }
  }

  .InnerDiv {
    // width: 1000px;
  }

  .Popup-Content {
      @include desktop {
        max-width: 530px;
    }
    @include mobile {
        max-width: 90%;
    }
  }

  .PopupButtons {
    width: 100%;
    // gap: 10%;
    display: flex;
    justify-content: space-between;
    margin-block-start: 10px;

    &-ShoppingBtn,
    &-CartBtn {
      width: 48%;
      text-align: center;
      padding: 0.8rem 0;
      border: 1px solid black;
      border-radius: 5px;
      cursor: pointer;
      font-size: 12px;
      height: 45px;
      font-weight: 600;
      line-height: 26px;
    }

    &-ShoppingBtn {
      @include mobile {
        color: #1d1d1d;
      }
    }

    &-CartBtn {
      background-color: #1d1d1d;
      color: #fff;
    }
  }

  .CartBtn {
    text-decoration: none;
    width: 48%;
    text-align: center;
    padding: 0.8rem 0;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    background-color: #1d1d1d;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .AddToCartBtn {
    border: 1px solid black;
    width: 45%;
  }

    .Popup {
        &-Header {
           display: block;
           padding-block-end: 20px;
           margin-block-end: 20px;
           border-bottom: 1px solid $border-color;
        }

        &-Heading {
            font-size: 24px;
            padding-inline-start: 40px;
            position: relative;
            margin-block-end: 0;

            @include desktop {
              font-weight: 700;
            }

            &::before {
                content:'';
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 17' xmlns='http://www.w3.org/2000/svg' role='img' aria-hidden='true' width='24' height='24'%3E%3Cg transform='translate(.5)' fill-rule='nonzero' fill='none'%3E%3Ccircle fill='%2300834b' cx='8' cy='8' r='8'%3E%3C/circle%3E%3Cg stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='m5.53 8.143 1.764 1.765M7.294 9.908l3.921-3.92'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                position: absolute;
                inset-inline-start: 0;
                transform: translateY(-50%);
                top: 50%;
                background-size: 100%;
            }
        }

        &-CloseBtn {
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 2px;
            width: 14px;
            height: 14px;
            cursor: pointer;
            
            &::before {
              content: '';
              width: 100%;
              height: 2px;
              background-color: $defult-primary-background-color;
              display: block;
              transform: rotate(45deg);
            }

            &::after {
              content: '';
              width: 100%;
              height: 2px;
              background-color: $defult-primary-background-color;
              display: block;
              transform: rotate(-45deg);
              position: relative;
              top: -2px;
            }

            svg {
                display: none;
            }
        }
    }
}
