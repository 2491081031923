/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --discount-color: #00834b;
}

.CartItem {
    &:last-child {
        border-bottom: 0;
    }

    &_isCartOverlay {
        .CartItem-Wrapper {
            &_isMobileLayout {
                grid-template-columns: 97px auto;
                padding: 18px 0;
                gap: 10px;
            }

            .CartItem-Picture_isMobileLayout {
                width: 97px;
                height: 110px;
            }
        }
    }

    .CartItem-Link {
        align-self: inherit;
    }

    &-OutOfStock {
        padding: 10px 0;
        color: red;

        @include mobile {
            padding: 10px 0;
            padding-inline-start: 0px;
        }
    }

    &-ProductInfo {
        &_isMobileLayout {
            flex-direction: column;

            .CartItem-PriceWrapper {
                display: flex;
                align-items: center;

                .qty {
                    margin-inline-end: 10px;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .OrderSummary-Discount {
            color: var(--discount-color);
        }
    }

    &-Price-qty {
        &_isSummary {
            display: none;
        }
    }

    &-Title {
        &_isMobileLayout {
            .CartItem-Heading {
                font-size: 12px;
                color: $default-primary-base-color;
                font-weight: 400;
                line-height: 16px;
                white-space: pre-wrap;

                @include mobile {
                    font-size: 14px;
                }
            }

            .CartItem-Options {
                display: flex;
                flex-direction: column;

                .Size {
                    &+ .Qty {
                        display: block;
                    }
                }

                .Color {
                    order: -1;
                }

                .option-sku {
                    display: none;
                }
            }
        }
    }

    &-Options {
        color: $default-primary-light-color;
        line-height: 16px;
        font-size: 10px;
        display: flex;
        flex-direction: column;

        span {
            display: block;

            &.Qty {
                display: none;
            }
        }

        .Color {
            order: 1;
        }

        .Size {
            order: 2;
        }

        .Qty {
            order: 3;
        }
    }

    &-ProductActions {
        &_isMobileLayout {
            grid-column: 1 / -1;
            padding-inline-end: 15px;
            align-items: center;
            margin-block-start: 10px;

            @include desktop {
                margin-block-start: 0px;
            }

            .ProductPrice {
                margin-block-end: 0;
                color: $default-primary-base-color;
                font-size: 12px;
                font-weight: 400;

                data {
                    font-size: 12px;
                }
            }
        }

        .CartItem-Delete {
            color: $default-primary-light-color;
            margin-inline-start: 0;

            .CartItem-DeleteButtonText {
                margin-inline-start: 0;
                display: inline-block;
            }

            &_isMobileLayout {
                @include desktop {
                    display: block;
                }
            }
        }

        .ProductPrice, .CartItemPrice {
            @include mobile {
                display: none;
            }
        }

        .giftCardPrice {
            display: none;
        }
    }

    &-Price {
        font-size: 12px;
        font-weight: 600;

        @include mobile {
            // font-weight: 400;
        }

        @include desktop {
            margin-inline-start: auto;
        }

        &_isMobileLayout {
            flex-direction: row;
        }
    }

    .CartItem-Qty {
        @include mobile {
            display: block;
        }
    }

    &-ItemInfo {
        @include desktop {
            flex: 1;
        }

        .CartItem-OutOfStock {
            @include desktop {
                padding-inline-start: 20px;
            }
        }
    }

    &-CartItemRows {
        @include mobile {
            width: calc(100% - 116px);
        }
    }

    &-DeleteForMinicart {
        display: none;

        &_isMobileLayout {
            @include desktop {
                display: block;
            }

            .CartItem-DeleteButtonText_isMobileLayout {
                display: block;
                color: $btn-primary-color;
            }
        }
    }

    &-Picture {
        img {
            position: relative;
        }

        &_isMobileLayout {
            width: 97px;
            height: 110px;
        }
    }
}

.giftCardPrice {
    font-size: 12px;
    font-weight: 600;
}
