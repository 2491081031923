/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ApplyGiftCart {
    margin-block: 0px 30px;
    @include desktop {
        padding-block-start: 15px;
        margin-block-start: 10px;
        border-block-start: 1px solid $border-color;
    }

    .ApplyGiftCart {
        &-Expandable {
            padding: 20px;
            border: 1px solid $border-color;
            border-radius: 4px;
            @include desktop {
                width: 75%;
            }

            @include mobile {
                padding: 15px;
            }
        }
        &-ExpandableContentHeading {
            @include desktop {
                font-size: 22px;
            }
            @include mobile {
                font-size: 16px;
            }
        }
    }
    .ExpandableContent {
        &-Button {
            cursor: pointer;
            @include desktop {
                padding: 0;
            }
        }

        &-Heading {
            font-weight: 600;
        }

        &-Content {
            @include desktop {
                margin-block-start: 10px;
            }
        }

        .Form {
            @include desktop {
                display: flex;
                flex-wrap: wrap;
            }

            .Field {
                margin-block-start: 0;
                &-Wrapper  {
                    width: 100%;
                }

                input {
                    @include desktop {
                        width: 100%;
                    }
                }
            }

            .Giftapply {
                @include desktop {
                    margin-inline-end: 10px;
                    margin-block-start: 10px;
                }

                @include mobile {
                    margin-block-start: 10px;
                }
            }
        }
    }

    &-Status {
        margin-block-start: 10px;
        margin-block-end: 10px;
        border:1px solid $border-color;
        padding: 15px;
        border-radius: 4px;

        p {
            margin-block-end: 0;
        }
    }

    &-AppliedStatus {
        border:1px solid $border-color;
        padding: 15px;
        border-radius: 4px;
        
        @include mobile {
            margin-block-end: 20px;
        }

        p{
            margin-block-end: 0;
            font-weight: 400;
            font-size: 14px;
            color:green;
            letter-spacing: 0.3px;
        }
    }

    &-Removebtn {
        color: $addtobag-backgorund-color;
        font-weight: 600;
        cursor: pointer;
    }
}
